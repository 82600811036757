import { defineComponent, defineAsyncComponent, toRefs, reactive, computed, watch, nextTick } from "vue";
import Eldialog from "@/components/Eldialog/index.vue";
import API from "@/api/education/index";
import AreaSelect from "@/components/AreaSelect/index.vue";
import { useMessage } from "@/hooks/web/useMessage";
import Emitter from "@/eventBus/index";
import { useDebounce } from '@/hooks/core/useDebounce';
import authorityAPI from "@/api/authority/index";
import TypeSelect from "@/components/TypeSelect/TypeSelect.vue";
export default defineComponent({
  name: "SchoolFormDialog",
  components: {
    Eldialog,
    AreaSelect,
    TypeSelect,
    OrganizeSelect: defineAsyncComponent(() => import("@/components/OrganizeSelect/index.vue")),
    Resource: defineAsyncComponent(() => import("../../authority/components/Resource.vue"))
  },
  props: {
    info: {
      type: Object,
      default: null
    },
    orgInfo: {
      type: Object,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:visible", "handleSuccess"],
  setup(props, {
    emit
  }) {
    const message = useMessage();
    const refData = reactive({
      roleOrgId: 1,
      refMapSearch: null,
      resourceVisible: false,
      resourceRef: null,
      value: "",
      searchValue: '',
      activeValue: null,
      selectAreaValue: [],
      roleType: [],
      ruleForm: null,
      formData: null,
      title: '新增单位',
      rules: {
        orgId: [{
          required: true,
          message: '请选择上级组织'
        }],
        num: [{
          required: true,
          message: '请填写部门数量'
        }],
        schoolName: [{
          required: true,
          message: "请输入所属单位",
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '长度在 1 到 20 个字符',
          trigger: 'blur'
        }, {
          pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/,
          message: '所属单位不能带有特殊符号'
        }],
        loginName: [{
          required: true,
          message: "请输入登录账号",
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '长度在 1 到 20 个字符',
          trigger: 'blur'
        }, {
          pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/,
          message: '登录账号不能带有特殊符号'
        }],
        roleId: [{
          required: true,
          message: "请选择登录权限",
          type: 'number',
          trigger: 'change'
        }],
        schoolType: [{
          required: true,
          message: "请选择单位性质",
          type: 'number',
          trigger: 'change'
        }],
        educationStage: [{
          required: true,
          message: "请选择阶段",
          type: 'number',
          trigger: 'change'
        }],
        headName: [{
          required: false,
          message: "请输入负责人姓名",
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '长度在 1 到 20 个字符',
          trigger: 'blur'
        }, {
          pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/,
          message: '负责人姓名不能带有特殊符号'
        }],
        headPhone: [{
          required: false,
          message: "请输入负责人电话",
          type: 'string',
          trigger: 'blur'
        }, {
          pattern: /^1[3456789]\d{9}$/,
          message: '请填写正确手机号码'
        }],
        schoolAddress: [{
          required: true,
          message: "请输入单位地址",
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 60,
          message: '长度在 1 到 40 个字符',
          trigger: 'blur'
        }, {
          pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/,
          message: '单位地址不能带有特殊符号'
        }],
        provinces: [{
          required: true,
          message: "请选择所属行政区域",
          type: 'string',
          trigger: 'change'
        }]
      },
      confirm() {
        refData.ruleForm.validate(async valid => {
          if (valid) {
            try {
              // if(!refData.formData.role.resourceIdList.length && refData.formData.roleType == 2) throw '请进入权限弹框，选择权限';
              let refMsg = '';
              let refFlag = 0;
              refData.formData.educationStage = 7;
              const params = JSON.parse(JSON.stringify(refData.formData));
              if (props.info === null) {
                const {
                  msg,
                  code
                } = await API.schoolAdd(params);
                refMsg = msg;
                refFlag = code;
              } else {
                delete params.loginName;
                const {
                  msg,
                  code
                } = await API.schoolUpdate({
                  id: props.info.schoolId,
                  ...params
                });
                refMsg = msg;
                refFlag = code;
              }
              if (refFlag !== 0) throw refMsg;
              message.success('成功');
              emit("update:visible", false);
              emit("handleSuccess");
              Emitter.emit('updateOrg');
              Emitter.emit('handleShow');
            } catch (error) {
              message.error(error);
            }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      async getData() {
        const {
          code,
          msg,
          data
        } = await API.getByIdschool({
          schoolId: props.info.schoolId
        });
        refData.formData = data;
        refData.searchValue = props.info.orgName;
        refData.selectAreaValue = [props.info.provinces, props.info.city, props.info.county];
        getRole();
        nextTick(() => {
          refData.ruleForm.clearValidate();
        });
      },
      handleSelectArea(item) {
        refData.formData.provinces = item[0];
        refData.formData.city = item[1];
        refData.formData.county = item[2];
      },
      //初始化
      init() {
        refData.formData = {
          num: 10,
          schoolName: null,
          schoolType: null,
          headName: null,
          orgId: null,
          headPhone: null,
          provinces: null,
          schoolAddress: null,
          city: null,
          county: null,
          educationStage: null,
          remark: '',
          roleId: 3,
          loginName: '',
          lngLat: []
        };
        refData.selectAreaValue = [];
        refData.searchValue = '';
      }
    });
    const show = computed({
      get: () => props.visible,
      set: val => {
        emit("update:visible", val);
      }
    });
    const handleDoubleClick = data => {
      refData.formData.orgId = data.id;
      getRole();
    };
    //获取当前账号下的全部角色
    const getRole = async () => {
      const {
        data,
        code,
        msg
      } = await authorityAPI.listRole({
        deptId: refData.formData.orgId ? refData.formData.orgId : 1
      });
      if (code === 0) {
        refData.roleType = data.list.map(item => ({
          value: item.id,
          label: item.name,
          ...item
        }));
      }
    };
    //显示/隐藏
    watch(() => props.visible, val => {
      if (val) {
        refData.init();
        if (props.info !== null) {
          refData.title = '修改单位';
          nextTick(() => {
            refData.init();
            refData.getData();
          });
        } else {
          refData.title = '新增单位';
          refData.init();
          nextTick(() => {
            refData.formData.orgId = props.orgInfo.id;
            getRole();
            refData.searchValue = props.orgInfo.name;
            refData.activeValue = props.orgInfo.activeValue;
            refData.ruleForm.clearValidate();
          });
        }
      }
    }, {
      immediate: true
    });
    //防抖
    const [useDebounceConfirm] = useDebounce(refData.confirm, 400);
    //权限弹框
    const visibleChange = roleType => {
      if (!refData.formData.orgId) return message.warning('请先选择上级组织');
      refData.resourceVisible = true;
      refData.roleOrgId = refData.formData.orgId;
      refData.resourceRef.type = refData.roleType.filter(item => roleType == item.value);
      refData.resourceRef.isAdd = false;
    };
    //新增角色
    const handleAddRole = () => {
      if (!refData.formData.orgId) return message.warning('请先选择上级组织');
      refData.resourceVisible = true;
      refData.roleOrgId = refData.formData.orgId;
      refData.resourceRef.isAdd = true;
    };
    // 选择地址回调
    const mapSearchSuccess = params => {
      refData.formData.schoolAddress = params.address;
      refData.formData.lngLat = params.address;
    };
    return {
      ...toRefs(refData),
      show,
      useDebounceConfirm,
      visibleChange,
      mapSearchSuccess,
      getRole,
      handleDoubleClick,
      handleAddRole
    };
  }
});